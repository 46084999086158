import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import {
  msgDetails,
  msgImage,
  confirmMsgDetails,
  msgConfirmWrapper,
} from "../../css/booking.module.css";

const BookingMessageConfirm = ({ room }) => {
  return (
    <div className={msgConfirmWrapper}>
      <h1>Booking request received</h1>
      <div className={confirmMsgDetails}>
        <div className={msgImage}>
          {room && (
            <GatsbyImage
              image={room.roomImage.childImageSharp.gatsbyImageData}
              alt={room.RoomTitle}
              imgStyle={{ objectFit: "cover" }}
              style={{ width: "100%", maxHeight: "300px" }}
            />
          )}
        </div>
        <div className={msgDetails}>
          <h3>We've received your request</h3>
          <p>A confirmation email has been sent to the address you gave us.</p>
          <p>
            Our volunteer committee will review your request and respond within
            24 hours.
          </p>
          <p>
            If you need to change your booking request in the meantime,{" "}
            <Link to="/contact">please get in touch</Link>.
          </p>
          <p>
            Download our{" "}
            <a href={room && room.hireAgreementLink}>hire agreement</a>.
          </p>
        </div>
      </div>
      <div>
        <Link to="/" className="btn-green">
          Done
        </Link>
      </div>
    </div>
  );
};

export default BookingMessageConfirm;
