import React, { useContext, useEffect, useState } from "react";

import { BookingContext } from "../../contexts/BookingContext";

import BookingRoomHeader from "../../components/booking/bookingRoomHeader";
import BookingDateTime from "../../components/booking/bookingDateTime";
import BookingRoomSummary from "../../components/booking/bookingRoomSummary";
import BookingMessageConfirm from "../../components/booking/bookingMsgConfirm";

import { bookingHoursCalc } from "../../utils/dateUtils";
import { buildBookingRequest } from "../../utils/messageUtils";

import { sendBookingRequest } from "../../actions/email";

import {
  bookingInfoWrapper,
  confirmConditionsWrapper,
  costTable,
  confirmCostsWrapper,
  bookingEventDetailsWrapper,
  bookingExtrasWrapper,
} from "../../css/booking.module.css";

const initialState = {
  submitting: false,
  error: false,
  success: false,
};

const BookingComplete = () => {
  const [bookingValues] = useContext(BookingContext);
  const [runningTotal, setRunningTotal] = useState();
  const [subValues, setSubValues] = useState(initialState);

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      if (bookingValues) {
        const {
          bookingDetailsEvent,
          bookingRoom,
          bookingDate,
          bookingFacilities,
        } = bookingValues;

        // get the hours
        let bookedHours = 1;
        if (bookingDate) {
          bookedHours = bookingHoursCalc(bookingDate.start, bookingDate.end);
        }

        // get the costs
        let extrasCost = 0;
        if (bookingFacilities) {
          extrasCost = bookingFacilities.reduce(
            (costSoFar, facility) => costSoFar + facility.cost,
            0
          );
        }

        // get the booking type selected
        let eventType = "";
        if (bookingDetailsEvent) {
          eventType = bookingDetailsEvent.bookingType;
        }

        let eventTypeCost = 0;
        if (bookingRoom) {
          const { cost } = bookingRoom.prices.find(
            (item) => item.bookingType.name === eventType
          );
          eventTypeCost = cost;
        }

        const basicRoomTotal = eventTypeCost * bookedHours;
        const eventTotal = basicRoomTotal + extrasCost;

        setRunningTotal({
          bookedHours,
          eventType,
          eventTypeCost,
          basicRoomTotal,
          extrasCost,
          eventTotal,
        });
      }
    }
    return () => {
      isCancelled = true;
    };
  }, [bookingValues]);

  const handleConfirmBtn = async () => {
    setSubValues({ ...subValues, submitting: true });
    // create a object for the email request
    if (bookingValues) {
      const requestMsg = await buildBookingRequest(bookingValues);
      // send a message
      const result = await sendBookingRequest(requestMsg);
      // show message rec
      if (result) {
        setSubValues({
          error: false,
          success: true,
          submitting: false,
        });
      }
    }
    return;
  };

  return (
    <>
      {subValues.success ? (
        <BookingMessageConfirm
          room={bookingValues && bookingValues.bookingRoom}
        />
      ) : (
        <div className="content-wrapper-column">
          <BookingRoomHeader />
          <BookingDateTime />
          <div className={bookingExtrasWrapper}>
            <div className={bookingEventDetailsWrapper}>
              <h2>Complete your booking</h2>
              <div className={confirmCostsWrapper}>
                <h3>Your price</h3>
                {runningTotal && (
                  <table className={costTable}>
                    <thead></thead>
                    <tbody>
                      <tr>
                        <td>Room cost:</td>
                        <td>£{runningTotal.eventTypeCost} per hour</td>
                      </tr>
                      <tr>
                        <td>Event length:</td>
                        <td>{runningTotal.bookedHours} hours</td>
                      </tr>
                      <tr>
                        <td>Extras:</td>
                        <td>£{runningTotal.extrasCost}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Total:</strong>
                        </td>
                        <td>
                          <strong>£{runningTotal.eventTotal}</strong> (includes
                          VAT)
                        </td>
                      </tr>
                    </tbody>
                  </table>
                )}
              </div>
              <div className={confirmConditionsWrapper}>
                <h3>Terms and conditions</h3>
                {bookingValues.bookingRoom && (
                  <p>
                    By submitting your booking request you are agreeing to the
                    terms of our{" "}
                    <a href={bookingValues.bookingRoom.hireAgreementLink}>
                      hire agreement
                    </a>
                    .
                  </p>
                )}

                <p>
                  Your booking is preliminary for now. Our volunteer committee
                  will be in touch within 24 hours to confirm.
                </p>
                <button
                  className="btn-green-light"
                  onClick={handleConfirmBtn}
                  disabled={subValues.submitting}
                >
                  Agree and submit
                </button>
              </div>
            </div>
            <div className={bookingInfoWrapper}>
              <BookingRoomSummary />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BookingComplete;
