import {
  bookingHoursCalc,
  readableDateString,
  readableTimeString,
} from "./dateUtils";

export const buildBookingRequest = async (booking) => {
  const {
    bookingContact: {
      fullName,
      email,
      phoneNumber,
      addressName,
      addressStreet,
      addressTown,
      addressCounty,
      postCode,
    },
    bookingDate: { start, end },
    bookingDetailsEvent: { bookingType, eventAbout, eventName, eventRepeat },
    bookingFacilities,
    bookingRoom: { RoomTitle, prices },
  } = booking;

  return {
    fullName,
    email,
    phoneNumber,
    addressName,
    addressStreet,
    addressTown,
    addressCounty,
    postCode,
    eventDate: readableDateString(start),
    eventStart: readableTimeString(start),
    eventEnd: readableTimeString(end),
    eventLength: `${bookingHoursCalc(start, end)} hours`,
    eventRoom: RoomTitle,
    eventType: `${bookingType} ${eventRepeat ? eventRepeat : ""}`,
    eventTypeCost: `£${bookingTypeCost(bookingType, prices)} per hour`,
    eventName,
    eventAbout,
    eventExtras: extrasToString(bookingFacilities),
    eventTotalCost: `£${totalBookingCost(
      start,
      end,
      bookingType,
      prices,
      bookingFacilities
    )} (includes VAT)`,
  };
};

const totalBookingCost = (start, end, bookingType, prices, extras) => {
  return (
    bookingHoursCalc(start, end) * bookingTypeCost(bookingType, prices) +
    totalExtrasCost(extras)
  );
};

const totalExtrasCost = (extras) =>
  extras.reduce((costSoFar, extra) => costSoFar + extra.cost, 0);

const bookingTypeCost = (type, prices) => {
  const { cost } = prices.find((item) => item.bookingType.name === type);
  return cost;
};

const extrasToString = (extras) => {
  if (extras.length > 0) {
    let extraString = "";
    for (let i = 0; i < extras.length; i++) {
      extraString =
        extraString +
        ` ${extras[i].name}  ${
          extras[i].cost === 0
            ? `(Free)`
            : `(£${extras[i].cost})${i + 1 === extras.length ? "" : ","}`
        }`;
    }
    return extraString;
  }

  return "none";
};
